::selection {
  background: #db5bdb;
}

.waves-effect.waves-pink .waves-ripple {
  background-color: #db5bdb;
}

.waves-effect.waves-dark .waves-ripple {
  background-color: #292d34;
}

#particles {
  z-index: -200000;
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
}

html {
  color: #fff;
  box-sizing: border-box;
  background-color: #0b0c11;
  font-family: Inter, sans-serif;
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
}

.btn, .btn-large, .btn-small {
  text-transform: none;
  box-shadow: none;
  border-radius: 999rem;
  transition: all .4s;
}

.btn:hover, .btn-large:hover, .btn-small:hover {
  transform: scale(1.05);
}

.btn-small {
  font-weight: 400;
}

.btn-clear {
  box-shadow: none;
  background: none;
  transition: all .2s;
}

.btn-clear:focus {
  background: none;
}

.btn-clear:hover {
  box-shadow: none;
  background: #6b71874d;
  transform: none;
}

.hover-rot-1:hover {
  filter: hue-rotate(40deg);
}

.hover-rot-2:hover {
  filter: hue-rotate(-40deg);
}

.grad {
  background: linear-gradient(45deg, #db5bdb 0%, #a384fb 43%, #41b7c9 100%);
}

.dl-btn-left, .dl-btn-right {
  width: 172px;
}

.dl-btn-left {
  background: linear-gradient(45deg, #db5bdb 0%, #a384fb 100%);
  border-radius: 999rem 0 0 999rem;
  margin: 0;
}

.dl-btn-right {
  background: linear-gradient(45deg, #a384fb 0%, #41b7c9 100%);
  border-radius: 0 999rem 999rem 0;
  margin: 0;
}

.flex-center {
  justify-content: center;
}

.logo-header {
  width: 90px;
  display: inline;
  position: relative;
}

.header-subtext {
  padding-bottom: 1.5rem;
}

@media (width <= 767px) {
  .header-subtext {
    margin-top: 1rem;
  }
}

.header-subtext span {
  color: #c8cfe6;
  font-size: 1.4rem;
  font-weight: 400;
}

@media (width <= 767px) {
  .header-subtext span {
    font-size: 1rem;
    font-weight: 400;
  }
}

.caption {
  margin-left: 2rem;
  font-size: 3rem;
  font-weight: 600;
  display: inline-block;
}

.les-badge {
  background-color: #0b0c11;
  border-radius: 200rem;
  padding: .3rem .8rem;
  font-size: 1.2rem;
  font-weight: 400;
}

.badge-new {
  letter-spacing: .1em;
  font-variant: small-caps;
  background: linear-gradient(45deg, #db5bdb 0%, #a384fb 43%, #41b7c9 100%);
  border-radius: 200rem;
  margin-left: 1rem;
  padding-left: .8rem;
  padding-right: .75rem;
  font-size: .9rem;
  font-weight: 600;
  display: inline-block;
  position: absolute;
}

.header-secondary-button {
  width: 8rem;
  font-size: .8rem;
  display: block;
}

.sep {
  background-color: #6b7187;
  width: 1px;
  height: 1.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: inline-block;
}

.darker-fucking-text-1 {
  color: #c8cfe6;
}

h1, h2, h3 {
  font-weight: 500;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.7rem;
}

.banner {
  text-align: center;
  min-height: 20rem;
  margin: 0 auto;
  padding-top: 6rem;
  display: block;
  position: relative;
}

@media (width <= 768px) {
  .banner .caption {
    font-size: 2rem;
  }

  .banner sup {
    font-size: .8rem;
  }
}

.banner:after {
  content: "";
  opacity: .3;
  z-index: -1;
  background-image: linear-gradient(#0b0c1100, #0b0c11), url("lesbanner.8c0983ec.png");
  background-size: cover;
  position: absolute;
  inset: 0;
}

.overview-item h2 {
  font-weight: 500;
}

.overview-item p {
  color: #c8cfe6;
}

.overview-item p em {
  color: #c8cfe6;
  font-style: normal;
  font-weight: 500;
}

.overview-item img {
  width: auto;
  max-height: 184px;
}

.special-h1 {
  color: #0b0c11;
  background: linear-gradient(45deg, #db5bdb 0%, #a384fb 43%, #41b7c9 100%);
  padding: 1rem;
}

.extra-pad-top {
  padding-top: 2rem;
}

.really-fat-pad-top-fuck-off {
  padding-top: 3rem;
}

.more-features ul {
  text-align: left;
}

.more-features ul li {
  color: #c8cfe6;
  padding: .5rem;
}

.testimonials p {
  color: #c8cfe6;
}

.testimonial {
  object-fit: cover;
  min-width: 10rem;
  max-width: 10rem;
  min-height: 10rem;
  max-height: 10rem;
  margin-top: 2rem;
}

.testimonial-grid .col {
  height: 28rem;
}

blockquote {
  color: #c8cfe6;
  border-left: none;
  padding-right: 1rem;
  font-style: italic;
}

blockquote:before {
  content: open-quote;
}

blockquote:after {
  content: close-quote;
}

.link-bar {
  padding-top: 3rem;
}

.page-footer {
  color: #6b7187;
  background-color: #0b0c11;
}

.page-footer .footer-copyright {
  color: #6b7187;
  background-color: #0000;
  padding-bottom: 3rem;
}

a {
  color: #a384fb;
}

a:hover {
  color: #fff;
  transition: all .2s ease-in-out;
}

.auxiliary-page-title {
  letter-spacing: .05rem;
  margin-left: 1rem;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
}

.mega-fat-pad-bottom {
  margin-bottom: 12rem;
}

li {
  list-style-type: disc !important;
}

code {
  color: #c8cfe6;
  background: #0b0c11;
  border: 2px solid #6b7187;
  border-radius: 4px;
  padding: 0 6px;
  font-family: Roboto Mono, monospace;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}

ul ul {
  margin-top: none;
  margin-left: 1rem;
}

.row {
  margin-bottom: 64px;
}

.section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.feature {
  align-items: center;
  display: flex;
}
/*# sourceMappingURL=changelog.686c3c2b.css.map */
